import { Enums } from '@flightscope/baseball-stats';
import { ChartTypeEnum } from './ChartHelpers';

const provider = (isMetric, chartType) => {
  let xaxis = {};
  let yaxis = {};
  switch (chartType) {
    case ChartTypeEnum.RELEASE_POINT.type:
      xaxis = {
        isSymmetric: true,
        minRange: isMetric ? 4 : 12,
        padding: isMetric ? 0.2 : 0.6,
        gridSize: isMetric ? 0.25 : 1,
      };
      yaxis = {
        isSymmetric: false,
        minRange: isMetric ? 2.5 : 8,
        padding: xaxis.padding,
        gridSize: xaxis.gridSize,
      };
      break;

    case ChartTypeEnum.PITCH_LOCATION.type:
      xaxis.isSymmetric = true;
      xaxis.minRange = isMetric ? 300 : 120;
      xaxis.padding = isMetric ? 10 : 4;
      xaxis.gridSize = isMetric ? 25 : 10;

      yaxis.minRange = isMetric ? 150 : 60;
      yaxis.padding = xaxis.padding;
      yaxis.gridSize = xaxis.gridSize;
      break;

    case ChartTypeEnum.RELEASE_EXTENSION.type:
      xaxis.minRange = isMetric ? 2 : 6;
      xaxis.padding = isMetric ? 0.1 : 0.3;
      xaxis.gridSize = isMetric ? 0.2 : 0.5;

      yaxis.minRange = isMetric ? 2 : 6;
      yaxis.padding = xaxis.padding;
      yaxis.gridSize = xaxis.gridSize;
      break;

    case ChartTypeEnum.PITCH_MOVEMENT.type:
      xaxis.isSymmetric = true;
      xaxis.minRange = isMetric ? 50 : 20;
      xaxis.padding = isMetric ? 5 : 2;
      xaxis.gridSize = isMetric ? 5 : 2.5;

      yaxis.isSymmetric = true;
      yaxis.minRange = xaxis.minRange;
      yaxis.padding = xaxis.padding;
      yaxis.gridSize = xaxis.gridSize;
      break;

    case ChartTypeEnum.SPRAY_CHART.type:
      switch (process.env.VUE_APP_SPORT_TYPE) {
        case Enums.SportType.Baseball.key:
          xaxis.minRange = isMetric ? 140 * 1.05 : 460 * 1.05;
          yaxis.minRange = isMetric ? 122 * 1.05 : 400 * 1.05;
          break;
        case Enums.SportType.Softball.key:
          xaxis.minRange = isMetric ? 105 * 1.05 : 350 * 1.05;
          yaxis.minRange = isMetric ? 90 * 1.05 : 300 * 1.05;
          break;
        default:
      }
      xaxis.isSymmetric = true;
      xaxis.padding = 0;
      xaxis.gridSize = isMetric ? 10 : 25;

      yaxis.padding = xaxis.padding;
      yaxis.gridSize = xaxis.gridSize;
      break;

    case ChartTypeEnum.CONTACT_REPORT.type:
    case ChartTypeEnum.CONTACT_REPORT_PITCHING.type:
    case ChartTypeEnum.STRIKE_ZONE.type:
      xaxis.isSymmetric = true;
      xaxis.minRange = isMetric ? 300 : 120;
      xaxis.padding = isMetric ? 10 : 4;
      xaxis.gridSize = isMetric ? 25 : 10;

      yaxis.minRange = isMetric ? 150 : 60;
      yaxis.padding = xaxis.padding;
      yaxis.gridSize = xaxis.gridSize;
      break;

    case ChartTypeEnum.PITCHER_PERFORMANCE.type:
    case ChartTypeEnum.BATTER_PERFORMANCE.type:
      // performance charts only padd Y axis
      break;

    default:
      throw new Error('invalid chart type');
    // NOOP;
  }
  return { xaxis, yaxis };
};

export default provider;
