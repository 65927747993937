import { unitSymbolProvider } from '../../../filters/units';
import { unitsConverter, conversionResult as unitConversionResult, conversionType } from '@/plugins/converter';

export default {
  props: {
    xAxisConversion: {
      type: String,
      default: conversionType.NUMBER,
      required: false,
    },
    xAxisPrecision: {
      type: Number,
      required: false,
    },
    yAxisConversion: {
      type: String,
      default: conversionType.NUMBER,
      required: false,
    },
    yAxisPrecision: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      seriesConverted: [],
      conversionResults: [],
    };
  },
  methods: {
    convertX(value, targetSystem, precision) {
      const roundingPrecision = typeof precision === 'number' ? precision : this.xAxisPrecision;
      let converted = this.convertValue(value, this.xAxisConversion, targetSystem);
      return this.getNumericValue(converted, roundingPrecision);
    },

    convertY(value, targetSystem, precision) {
      const roundingPrecision = typeof precision === 'number' ? precision : this.yAxisPrecision;
      let converted = this.convertValue(value, this.yAxisConversion, targetSystem);
      return this.getNumericValue(converted, roundingPrecision);
    },

    convertSeries(seriesSi, targetSystem, precisionX, precisionY) {
      const roundingPrecisionX = typeof precisionX === 'number' ? precisionX : this.xAxisPrecision;
      const roundingPrecisionY = typeof precisionY === 'number' ? precisionY : this.yAxisPrecision;

      let convertedSeries = [];
      let results = [];

      for (let i = 0; i < seriesSi.length; i++) {
        let siSet = seriesSi[i];
        let convertedSet = Object.assign({}, siSet);
        let conversionResults = Object.assign({}, siSet);

        if (siSet && siSet.data && siSet.data.hasOwnProperty('length')) {
          convertedSet.data = [];
          conversionResults.data = [];

          for (let j = 0; j < siSet.data.length; j++) {
            let value = siSet.data[j];
            // check what structure do we have, possible options are array with numbers,
            // array with objects containing x and y property and array of arrays, where first element is x and 2nd is y
            // more @ https://apexcharts.com/docs/series/
            if (typeof value === 'number') {
              let convertedY = this.convertValue(value, this.yAxisConversion, targetSystem);
              conversionResults.data[j] = convertedY;
              convertedSet.data[j] = this.getNumericValue(convertedY, roundingPrecisionY);
            } else if (value instanceof Array) {
              let x = value[0];
              let y = value[1];

              let convertedX = this.convertValue(x, this.xAxisConversion, targetSystem);
              let convertedY = this.convertValue(y, this.yAxisConversion, targetSystem);

              conversionResults.data[j] = [convertedX, convertedY];
              convertedSet.data[j] = [
                this.getNumericValue(convertedX, roundingPrecisionX),
                this.getNumericValue(convertedY, roundingPrecisionY),
              ];
            } else if (value instanceof Object) {
              let { x, y } = value;

              let convertedX = this.convertValue(x, this.xAxisConversion, targetSystem);
              let convertedY = this.convertValue(y, this.yAxisConversion, targetSystem);

              conversionResults.data[j] = { x: convertedX, y: convertedY };
              convertedSet.data[j] = {
                x: this.getNumericValue(convertedX, roundingPrecisionX),
                y: this.getNumericValue(convertedY, roundingPrecisionY),
              };
            }
          }
        }

        convertedSeries[i] = convertedSet;
        results[i] = conversionResults;
      }
      this.seriesConverted = convertedSeries;
      this.conversionResults = results;

      return this.seriesConverted;
    },
    getNumericValue(conversionResult, roundingPrecision) {
      if (conversionResult.hasValue() === false) {
        return NaN;
      }

      if (typeof roundingPrecision === 'number') {
        return conversionResult.round(roundingPrecision);
      }

      return conversionResult.value;
    },
    convertValue(value, type, targetSystem) {
      return unitsConverter.convertType(value, type, targetSystem);
    },
    unitSymbolX(targetSystem, symbolIndex) {
      return unitSymbolProvider(this.xAxisConversion, targetSystem, symbolIndex || 0);
    },
    unitSymbolY(targetSystem, symbolIndex) {
      return unitSymbolProvider(this.yAxisConversion, targetSystem, symbolIndex || 0);
    },
    roundValue(value, numOfFractionals) {
      return unitConversionResult.round(value, numOfFractionals);
      //return unitConversionResult.stringify(rounded, undefined, undefined, 2);
    },
  },
};
