import PlotlyGraph from '@/components/ui/charts/plotly/PlotlyGraph.vue';

export default {
  components: {
    PlotlyGraph,
  },
  data() {
    return {
      plotlyData: [],
      plotlyLayout: {},
      plotlyConfig: {},
      plotlyLayoutExtended: {},
      plotlyCounter: 0,
    };
  },
};
