import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import sanitize from 'sanitize-filename';
import { Enums } from '@flightscope/baseball-stats';
import determineChartAxisRange from '@/providers/ChartAxisRangeProvider';
import PlotlyBased from '@/components/mixins/charts/PlotlyBased';
import { Layouts, Configs, SeriesFields } from '@/components/ui/charts/plotly/PlotlyHelpers';
import { ChartTypeEnum } from '@/components/ui/charts/ChartHelpers';
import axisRangeSettingsProvider from '@/components/ui/charts/AxisRangeSettingsProvider';
import ChartMiddleware from './ChartMiddleware.vue';
import mapDefaultTraceSettings from '../mappers/mapDefaultTraceSettings';

const defaultAxisSetting = {
  min: undefined,
  max: undefined,
  isSymmetric: false,
  minRange: 1,
  padding: 1,
  gridSize: 1,
};

export default Vue.extend({
  name: 'PlotlyChartMiddleware',

  extends: ChartMiddleware,

  mixins: [PlotlyBased],

  props: {
    staticPlot: Boolean,

    additionalLayout: {
      type: Object,
      default: () => ({}),
    },

    hoverTextFormatter: {
      type: Function,
    }
  },

  data() {
    return {
      plotlyLayout: Layouts.base,
      plotlyLayoutExtended: {},
      plotlyConfig: Object.assign({}, Configs.base),
      adjustRanges: false,

      chartType: ChartTypeEnum.DEFAULT.type,

      axisSettings: {
        xaxis: {
          ...defaultAxisSetting,
        },
        yaxis: {
          ...defaultAxisSetting,
        },
      },
    };
  },

  computed: {
    chartLayout() {
      return merge({}, this.additionalLayout, this.plotlyLayout, this.plotlyLayoutExtended);
    },
  },

  methods: {
    /**
     *
     * @param {Object} axis
     * @param {Number} axis.min
     * @param {Number} axis.max
     * @param {Boolean} axis.isSymmetric
     * @param {Number} axis.minRange
     * @param {Number} axis.padding
     * @param {Number} axis.gridSize
     */
    adjustAxis(axisObj, conversionType, { min, max, isSymmetric, minRange, padding, gridSize }) {
      let values = [min, max]
        .filter((i) => i !== undefined && typeof i === 'number')
        .map((i) => {
          return this.convertToCurrent(i, conversionType).value;
        });

      let range = determineChartAxisRange(values, isSymmetric, minRange, padding, gridSize);

      axisObj.range = [range.min, range.max];

      if (gridSize) {
        axisObj.dtick = gridSize;
      }
    },

    axisAdjuster() {
      this.adjustAxis(this.plotlyLayoutExtended.xaxis, this.xAxisConversion, this.axisSettings.xaxis);

      this.adjustAxis(this.plotlyLayoutExtended.yaxis, this.yAxisConversion, this.axisSettings.yaxis);
    },

    configureAxisAdjuster() {
      const { xaxis, yaxis } = axisRangeSettingsProvider(this.isMetric, this.chartType);
      this.axisSettings.xaxis = {
        ...this.axisSettings.xaxis,
        ...xaxis,
      };
      this.axisSettings.yaxis = {
        ...this.axisSettings.yaxis,
        ...yaxis,
      };
    },

    mapDefaultTraceSettings,

    getColorsFromSeries(series) {
      return series.map((item) => item.color);
    },

    convertSeries(seriesSi, targetSystem, precisionX, precisionY, fields = [SeriesFields.X, SeriesFields.Y]) {
      const roundingPrecisionX = typeof precisionX === 'number' ? precisionX : this.xAxisPrecision;
      const roundingPrecisionY = typeof precisionY === 'number' ? precisionY : this.yAxisPrecision;

      let convertedSeries = seriesSi.map((set) => {
        let convertedSet = cloneDeep(set);

        if (set.hasOwnProperty(SeriesFields.X) && fields.includes(SeriesFields.X)) {
          convertedSet.x = set.x.map((value) => {
            if (typeof value === 'number') {
              let convertedX = this.convertValue(value, this.xAxisConversion, targetSystem);
              return this.getNumericValue(convertedX, roundingPrecisionX);
            }
            return value;
          });
        }

        if (set.hasOwnProperty(SeriesFields.Y) && fields.includes(SeriesFields.Y)) {
          convertedSet.y = set.y.map((value) => {
            if (typeof value === 'number') {
              let convertedY = this.convertValue(value, this.yAxisConversion, targetSystem);
              return this.getNumericValue(convertedY, roundingPrecisionY);
            }
            return value;
          });
        }

        if (set.hasOwnProperty(SeriesFields.R) && fields.includes(SeriesFields.R)) {
          convertedSet[SeriesFields.R] = set[SeriesFields.R].map((value) => {
            if (typeof value === 'number') {
              let convertedR = this.convertValue(value, this.yAxisConversion, targetSystem);
              return this.getNumericValue(convertedR, roundingPrecisionY);
            }
            return value;
          });
        }

        return convertedSet;
      });
      return convertedSeries;
    },

    getMinMaxForSeries(series) {
      this.axisSettings.xaxis.min = series.applyCallback(Enums.SeriesDimension.X, Math.min, undefined);
      this.axisSettings.xaxis.max = series.applyCallback(Enums.SeriesDimension.X, Math.max, undefined);
      this.axisSettings.yaxis.min = series.applyCallback(Enums.SeriesDimension.Y, Math.min, undefined);
      this.axisSettings.yaxis.max = series.applyCallback(Enums.SeriesDimension.Y, Math.max, undefined);
    },

    customMapping(converted) {
      // this.$log.debug(`Running from ${this.$options.name}`);
      // this.$log.debug(`Should be overwritten by extending component - ex. ${this.$options.name}`);
    },

    seriesConverter(mapped) {
      return this.convertSeries(mapped, this.unitSystem.system);
    },

    updateSeries() {
      this.$log.debug(`Running from ${this.$options.name}`);

      let series = this.seriesProvider();

      if (!series || !series.length) {
        this.loading = false;
        return;
      }

      this.loading = true;

      if (!this.seriesMapper) {
        throw new Error('seriesMapper should be injected');
      }

      /*
       * General approach
       *
       * 1. Get min/max for chart series
       * 2. Extend series using overload of seriesExtended()
       * 3. Map to plotly dataset
       * 4. Map set name
       * 5. Map set color
       * 6. Convert set data to current unit system
       * 7. Run customMapping method, which can:
       *    1. Do additional mapping on the sets
       *    2. Make changes in config/layout
       *    3. Adjust ranges if needed
       * 8. Bump counter
       * 9. Disable loader
       */

      this.getMinMaxForSeries(series);

      const mapper = new this.seriesMapper();

      const seriesExtended = this.seriesExtended(series);

      let mapped = mapper.map(seriesExtended);

      if (this.enumSet) {
        mapped = mapped.map(this.mapSeriesNames);
      }

      if (this.colorMapper) {
        mapped = mapped.map(this.getColorForSet);
      }

      if (this.adjustRanges) {
        this.configureAxisAdjuster();
        this.axisAdjuster();
      }

      const converted = this.seriesConverter(mapped);

      this.customMapping(converted);

      this.configureChart();

      this.plotlyCounter++;

      this.loading = false;
    },

    configureChart() {
      // 1. customized download, @see https://plotly.com/javascript/configuration-options/#customize-download-plot-options
      // todo - inject context or meta data which can be used to
      let filename = `${new Date().toISOString()}-${this.plotlyLayoutExtended?.title?.text}`;
      this.plotlyConfig.toImageButtonOptions.filename = sanitize(filename);

      if (this.staticPlot) {
        this.plotlyConfig.staticPlot = true;
      }
    },
  },
});
