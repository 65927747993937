import { AxisWithUnits } from '@/components/mixins/charts';
import { measurementSystem, convertValue } from '@/plugins/converter';
import { Providers, Entities } from '@flightscope/baseball-stats';
import { getColorForSet } from '../ColorMapping';

export default {
  name: 'ChartMiddleware',

  mixins: [AxisWithUnits],

  props: {
    seriesProvider: {
      type: Function,
      required: true,
    },

    counter: {
      type: Number,
      default: 0,
    },

    // depends on unit system
    unitSystem: {
      type: Object,
    },

    enumSet: {
      type: Object,
    },

    // todo > consider injecting a series formatter, which will handle units conversion, color mapping, series name formatting etc, maybe even mapping
    colorMapper: {
      type: Object,
    },

    enabledHover: {
      type: Boolean,
      default: false,
    },

    enabledMarkerClick: {
      type: Boolean,
      default: false,
    },

    externalLoading: {
      type: Boolean,
      default: false,
    },

    seriesMapper: {
      type: Function,
    },

    xAxisTitle: {
      type: String,
      default: 'X'
    },

    yAxisTitle: {
      type: String,
      default: 'Y'
    },
  },

  model: {
    prop: ['externalLoading'],
    event: 'input',
  },

  computed: {
    loading: {
      get() {
        return this.externalLoading || false;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    isMetric() {
      return this.unitSystem.system === measurementSystem.METRIC;
    },

    watchedData() {
      return {
        counter: this.counter,
        unitSystem: this.unitSystem,
      };
    },
  },

  methods: {
    /**
     *
     * @param {*} value
     * @param {*} type
     * @param {*} targetSystem
     */
    convertValue,

    convertToCurrent(value, type) {
      return this.convertValue(value, type, this.unitSystem.system);
    },

    customMapping(converted) {
      this.$log.debug(`Running from ${this.$options.name}`);
      this.$log.debug(`Should be overwritten by extending component - ex. ${this.$options.name}`);
      throw new Error('implement this !!!');
    },

    updateSeries() {
      this.$log.debug(`Running from ${this.$options.name}`);
      this.$log.debug(`Should be overwritten by extending component - ex. ${this.$options.name}`);
      throw new Error('implement this !!!');
    },

    getColorForSet(set) {
      set.color = getColorForSet(set.key, this.colorMapper);
      return set;
    },

    mapSeriesNames(item) {
      if (!item.hasOwnProperty('key')) {
        return item;
      }
      let nameMapped = '';

      // todo - translate, enum, inject
      switch (item.key) {
        case 'strikeZone':
          nameMapped = 'Strike Zone';
          break;

        case 'strikeZoneSectors':
          nameMapped = 'Sectors';
          break;

        case 'grassLine':
          nameMapped = 'Grass Line';
          break;

        case 'foulLine':
          nameMapped = 'Foul Line';
          break;

        case 'hiddenSeries':
          nameMapped = ' ';
          break;

        default:
          nameMapped = Providers.EnumValueProvider.getValue(item.key, this.enumSet, this.enumSet.Unidentified.key).name;
          break;
      }

      if (nameMapped) {
        item.name = nameMapped;
      }

      return item;
    },

    seriesExtended(series) {
      if (series instanceof Array) {
        return series;
      }
      return new Entities.ChartSeries();
    },

    hoverHandler(item) {
      if (!this.enabledHover) {
        return;
      }

      this.$emit('hover-point', item);
    },

    markerClickHandler(point) {
      if (!this.enabledMarkerClick) {
        return;
      }

      if (point.event.touches) {
        this.$emit('tap-point', point);
        return;
      }

      this.$emit('click-point', point);
    },
  },

  mounted() {
    this.updateSeries();
  },

  watch: {
    watchedData: {
      handler: 'updateSeries',
      deep: true,
    },
  },
};
